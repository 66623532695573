body {
    background-color: #343a40;
    color: #FFFFFF;
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.cigar-table {
    padding-left: 5rem;
    padding-right: 5rem;
}

a:link { text-decoration: none; color: inherit; }
a:visited { text-decoration: none; color: inherit;  }
a:hover { text-decoration: none; color: inherit;  }
a:active { text-decoration: none; color: inherit;  }

.chart-width {
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 825px;
}

.logo-width {
    object-fit: contain;
    display: block;
    margin-left: auto;
    margin-right: auto;
    max-width: 5rem;
}

.signup {
    display: block;
    margin: auto;
    max-width: 50%;
}

.boxes {
    margin: 0.5rem;
}

.tiny {
    font-size: small;
}

.messages {
    margin-top: 5rem;
    text-align: center;
    font-size: larger;
    padding: 0.5rem;
}

.home-text-style {
    font-size: 1.25rem;
    margin: auto;
}

.home-form-style {
    font-size: larger;
    margin-left: 25%;
    margin-right: 25%;
}

.navbar li:last-child {
    float: right; /* last li item */
}

.center {
    margin: auto;
}

.about {
    font-size: larger;
    text-align: center;
}

.nav-font-size {
    font-size: x-large;
    font-weight: 400;
}

.paginate {
    position: relative;
    align-items:center;
}

.paginate>li:last-child {
    right: 0;
    position: relative;
    align-items:center;
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
    align-items:center;
}

.pagination>.active>a {
    color: #FFFFFF;
    align-items:center;
}

.pagination>li>a {
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
    align-items:center;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    color: #F7D300;
    font-size: x-large;
    font-weight: bold;
    align-items:center;
}

.pagination>li>a,
.pagination>li>span {
    color: #FFFFFF;
    align-items:center;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset;
    align-items:center;
}
